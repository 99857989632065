<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading resources list
  </loading-spinner>
  <div v-else>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <div class="input-group">
        <span class="input-group-text bg-white">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-start-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search resources by name"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
    </div>
    <div
      class="
        pb-3
        d-flex
        justify-content-between
        align-items-center
        flex-column flex-sm-row
      "
    >
      <div>
        <span class="badge bg-dark shadow-sm py-2 fw-normal">
          Total Resources: {{ resources.length }}
        </span>
      </div>
      <button
        class="btn btn-sm btn-primary px-3 mt-2 mt-sm-0"
        @click="viewResource()"
      >
        <i class="fas fa-plus me-2" />
        Add resource
      </button>
    </div>
    <div class="d-flex flex-row-reverse">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ 'active': currentPage === pageNumber }">
            <a class="page-link" href="#" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
          </li>
          <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click="nextPage">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div
      class="
        table-responsive
        rounded
        shadow-sm
        rounded
        border border-light
        position-relative
      "
    >
      <table class="table lh-sm table-admin w-100">
        <thead>
          <tr>
            <th class="pe-0">Title</th>
            <th class="pe-0">Thumbnail</th>
            <th class="pe-0">Video</th>
            <th class="text-center ps-0">Link</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="rsc in displayedData" :key="rsc._id">
            <td class="text-nowrap text-truncate pe-0">
              <button
                class="
                  btn btn-link
                  p-0
                  w-100
                  lh-sm
                  clickable
                  text-start text-decoration-none text-truncate
                "
                :title="rsc.title"
                v-html="rsc.title"
                @click="viewResource(rsc)"
              ></button>
            </td>
            <td
              class="text-nowrap text-truncate pe-0"
            >
              <img style="height: 25px; width: 25px" class="shadow-sm rounded clickable" v-if="rsc.thumbnail" :src="rsc.thumbnail.url" @click.stop="currentMedia = rsc.thumbnail.url">
            </td>
            <td
              class="text-nowrap text-truncate pe-0"
            >
              <a v-if="rsc.video" :href="'https://' + rsc.video.url" target="_blank" class="clickable">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="height: 20px; color:grey;">
                  <path d="M4.5 4.5a3 3 0 00-3 3v9a3 3 0 003 3h8.25a3 3 0 003-3v-9a3 3 0 00-3-3H4.5zM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06z" />
                </svg>
              </a>
            </td>
            <td
              class="text-nowrap text-truncate ps-0"
              style="width: 1%"
            >
              <a :href="rsc.url" target="_blank" class="mx-1" v-if="rsc.url">
                <span v-html="rsc.url"></span>
              </a>
            </td>
          </tr>
          <tr v-if="computedresources.length < 1">
            <td colspan="8" class="text-center fst-italic text-muted">
              No class found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4 d-flex flex-row-reverse">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ 'active': currentPage === pageNumber }">
            <a class="page-link" href="#" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
          </li>
          <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click="nextPage">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="currentMedia"
      @close="currentMedia = null"
    />
    <manage-resource-form
      v-if="showResourceModal"
      :rsc="currentClass"
      @close="closeClassModal"
      @update="updateClassDetails"
      @delete="closeClassModal($event, 'delete')"
      @add="closeClassModal($event, 'add')"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";
import ManageResourceForm from "@/components/Modals/ManageResourceForm";

export default {
  components: { LoadingSpinner, MediaViewer, ManageResourceForm },
  data() {
    return {
      isLoading: false,
      resources: [],
      search: "",
      currentMedia: null,
      currentClass: null,
      showResourceModal: false,
      filterStatus: null,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    computedresources() {
      const search = this.search.toLowerCase();

      let result = [...this.resources];

      if (this.filterStatus) {
        result = result.filter(
          (enquiry) => enquiry.status == this.filterStatus
        );
      }

      return result.filter((enquiry) =>
        enquiry.title.toLowerCase().includes(search)
      );
    },
    totalPages() {
      return Math.ceil(this.resources.length / this.itemsPerPage);
    },
    displayedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      
      if(this.search != ''){
        return this.computedresources
      } else {
        return this.resources.slice(startIndex, endIndex);
      }
    }
  },
  methods: {
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.search = '';
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.search = '';
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.search = '';
      }
    },
    updateClassDetails(rsc) {
      if (rsc) {
        this.currentClass = rsc;

        let classIndex = this.resources.findIndex((w) => w._id == rsc._id);

        if (classIndex > -1) {
          this.$set(this.resources, classIndex, rsc);
        }
      }
    },
    viewResource(rsc) {
      this.currentClass = rsc;

      this.showResourceModal = true;
    },
    closeClassModal(rsc, type) {
      this.currentClass = null;

      if (rsc) {
        if (type == "add") {
          this.resources.push(rsc);
        } else {
          const classIndex = this.resources.findIndex((w) => w._id == rsc._id);

          if (classIndex > -1) {
            if (type == "delete") {
              this.resources = this.resources.filter((w) => w._id != rsc._id);
            } else {
              this.$set(this.resources, classIndex, rsc);
            }
          }
        }
      }

      this.showResourceModal = false;
    },
    getresources() {
      this.isLoading = true;

      this.API.get("featured-videos?_limit=-1")
        .then((retVal) => {
          this.resources = retVal.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getresources();
  },
};
</script>